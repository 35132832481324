import * as React from "react";
import { Seo } from "../components/seo.js";
import Layout from "../components/layout.js";
import How from "../components/how.js";
import Team from "../components/team.js";
import OurWork from "../components/work.js";

export const Head = () => <Seo />;

const IndexPage = () => {
  return (
    <Layout>
      <div className="page-title-section">
        <video
          id="background-video"
          playsInline
          autoPlay
          loop
          muted
          poster={require(`../images/st36-header-video-image.webp`).default}
        >
          <source
            src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/st36-header.webm"
            type="video/webm"
          />
        </video>
        <span className="page-title-overlay"></span>
        <div className="container">
          <h1>LandTech's Creative Agency</h1>
          <h2>
            You're already great. We make sure everyone else thinks so too.
            Studio 36, your creative partner (not just another department).
          </h2>
        </div>
      </div>

      <How />
      <Team />
      <OurWork />
    </Layout>
  );
};

export default IndexPage;
