import * as React from "react";
import { useRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function OurWork() {
  var data = require("../json/work.json");
  data = data.our_work;

  const divStyle = (src) => ({
    backgroundImage: "url(" + src + ")",
  });

  const scrollVideo = useRef(null);
  const sideAnimate = useRef(null);

  useEffect(() => {
    let breakPoints = gsap.matchMedia();

    breakPoints.add("(min-width: 1001px)", () => {
      const ourWorkTl = gsap.timeline({
        scrollTrigger: {
          trigger: "#ourWork",
          start: "top 600",
          end: "top 100",
          scrub: 1,
          // markers: true,
        },
      });
      ourWorkTl.from(".work-item:nth-of-type(1)", { y: -150, opacity: 0 });
      ourWorkTl.from(".work-item:nth-of-type(2)", { y: -150, opacity: 0 });
      ourWorkTl.from(".work-item:nth-of-type(3)", { y: -150, opacity: 0 });

      let fadein = gsap.utils.toArray(".fade-in ");
      fadein.forEach((item, index) => {
        let sideAnimateTl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: "top center",
            end: "top 600",
            scrub: 1,
            // markers: true,
          },
        });
        if (item.classList.contains("animateRtl")) {
          sideAnimateTl.from(item, { x: 500, opacity: 0, duration: 0.8 });
        } else {
          sideAnimateTl.from(item, { x: -500, opacity: 0, duration: 0.8 });
        }
      });

      let videoTl = gsap.timeline({
        scrollTrigger: {
          trigger: "#infoGraphVideo",
          start: "top 90",
          end: "+=" + window.innerHeight * 2,
          scrub: 2,
          pin: true,
          //markers: true,
        },
      });
      videoTl.to("#infoGraphVideo", { currentTime: 50 });
    });
  }, []);

  return (
    <>
      <div id="ourWork">
        <div className="text-row container">
          <h2>Our Work</h2>
        </div>
        <div className="our-work-items">
          {data.map((data) => (
            <Link
              to={data.link}
              className="work-item"
              key={data.workId}
              style={divStyle(require(`../images/work/${data.image}`).default)}
            >
              <span className="work-item-text">
                <span className="work-item-title">{data.work}</span>
                <span>Learn more</span>
                {/* {data.description} */}
              </span>
            </Link>
          ))}
        </div>

        <div id="graphicDesign">
          <div className="pw-left">
            <h2 className="row-title">Graphic Design</h2>
            <p>
              We can make anything look brilliant, including event banners,
              website pages, video thumbnails etc.
            </p>
          </div>
          <div className="pw-right">
            <img
              src={require(`../images/work/infographic-printed.webp`).default}
              alt=""
            />
            <img
              src={require(`../images/work/mipim-stand.webp`).default}
              alt=""
            />
            <img src={require(`../images/work/stand.webp`).default} alt="" />
          </div>
        </div>

        <div className="animation-wrapper">
          <div className="work-sections row-reverse">
            <div className="ws-text">
              <h3>Print</h3>
              <p>
                It's the 21st century, so of course screens are cool. But
                sometimes there's nothing better than a physical copy of a book,
                brochure etc. Let us know if you fancy going a bit old school.
              </p>
            </div>
            <div className="fade-in animateLtr" ref={sideAnimate}>
              <img
                src={require(`../images/work/infographic-printed.webp`).default}
                alt=""
              />
            </div>
          </div>
          <div className="work-sections">
            <div className="ws-text">
              <h3>Digital</h3>
              <p>
                If you'd prefer to have something that you can share with a
                click, give us a shout, you could end up with something like
                this.
              </p>
            </div>
            <div className="fade-in animateRtl" ref={sideAnimate}>
              <video
                className="video"
                playsInline={true}
                preload="auto"
                controls="controls"
              >
                <source
                  src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/infographic-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>

        <div id="writtenAssets">
          <div className="text-row container">
            <h2 className="row-title">Written Assets</h2>
            <p>
              We can write pretty much anything, including blog posts, white
              papers, infographics, and paid ads.
            </p>
          </div>
          <div className="animation-wrapper">
            <div className="work-sections">
              <div className="fade-in animateLtr" ref={sideAnimate}>
                <img
                  src={
                    require(`../images/work/written-assets-one.webp`).default
                  }
                  alt=""
                />
              </div>
              <div className="fade-in animateRtl" ref={sideAnimate}>
                <img
                  src={require(`../images/work/infographic.webp`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div id="videoWork">
          <div className="container">
            <div className="videos-wrapper">
              <div className="video-item vi-text">
                <h2 className="row-title">Audio</h2>
                <p>
                  This is something we'd like to do a bit more of, in the form
                  of podcasts and interviews, let us know if you're interested
                  in doing one, and why.
                </p>
              </div>

              <div className="video-item">
                <video
                  className="video"
                  playsInline={true}
                  preload="auto"
                  controls="controls"
                >
                  <source
                    src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/jonnys-blog-audio.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="videos-wrapper">
              <div className="video-wrapper-text">
                <h2 className="row-title">Video</h2>
                <p>
                  We can also create a wide range of video content, including
                  talking heads, animation, and long form documentaries.
                </p>
              </div>

              <div className="video-item">
                <video
                  className="video"
                  playsInline={true}
                  preload="auto"
                  controls="controls"
                >
                  <source
                    src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/drone-video.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="video-item">
                <video
                  className="video"
                  playsInline={true}
                  preload="auto"
                  controls="controls"
                >
                  <source
                    src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/landfund-trailer.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className="text-row container mobile-off">
          <p>
            Videos can also be used as scrollable website elements to make it
            seem like a whole section of the website has been animated. Scroll
            through the video below.
          </p>
        </div>
        <video
          id="infoGraphVideo"
          ref={scrollVideo}
          className="video mobile-off"
          playsInline={true}
          preload="auto"
          muted="muted"
        >
          <source
            src="https://2979356.fs1.hubspotusercontent-na1.net/hubfs/2979356/julian-global-assets/thirty-six/infographic-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </>
  );
}
