import * as React from "react";

export default function How() {
  return (
    <>
      <div id="howItWorks">
        <div className="text-row container">
          <h2>How it works</h2>
        </div>
        <div className="container">
          <div className="how-text">
            <div className="how-section">
              <div className="hs-text">
                <h3>
                  1. Complete your creative brief. Give us as much information
                  as possible, every little helps.
                </h3>
                <a
                  href="https://forms.clickup.com/20459680/f/kgc50-24648/809AM6CXUPUNIF5CRH"
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  Submit a brief
                </a>
              </div>
              <div>
                <img
                  src={require(`../images/how/step-one.svg`).default}
                  alt=""
                />
              </div>
            </div>

            <div className="how-section">
              <div className="hs-text">
                <h3>
                  2. We'll be in touch to discuss the best options for you
                </h3>
              </div>
              <div>
                <img
                  src={require(`../images/how/step-two.svg`).default}
                  alt=""
                />
              </div>
            </div>

            <div className="how-section">
              <div className="hs-text">
                <h3>3. All deliverables come with SLA commitments</h3>
                <a
                  href="https://docs.google.com/spreadsheets/d/14zlFqWosFX5dFfL1brfOi3ze7LUEMfy2WYcz9VVowt0/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  className="btn"
                >
                  Check SLA timelines
                </a>
              </div>
              <div>
                <img
                  src={require(`../images/how/step-three.svg`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
