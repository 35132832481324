import * as React from "react";

export default function Team() {
  var data = require("../json/team.json");
  data = data.our_team;

  return (
    <>
      <div id="ourTeam">
        <div className="container">
          <div className="about-text">
            <h2>Our Team</h2>
          </div>
          <div className="team-wrapper">
            {data.map((data) => (
              <div className="team-member" key={data.teamId}>
                <div className="flip-card-inner">
                  <div className="flip-card front">
                    <img
                      src={require(`../images/team/${data.icon}`).default}
                      alt={data.name}
                    />
                  </div>
                  <div className="flip-card back">
                    <img
                      src={require(`../images/team/${data.headshot}`).default}
                      alt={data.name}
                    />
                  </div>
                </div>
                <h3>{data.name}</h3>
                <h4>{data.job_role}</h4>
                {/* <p>{data.description}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
